/* Main colors */
$bg-main-color: #FFFFFF;
$primary-text-color: #316FEA;
$title-text-color: #1A1919;
$border-color: #D3D8DC;
$placeholder-text-color: #A1ABB5;
$error-color: #CB3D40;

/* Buttons color */
$social-text-color: #060E1E;

/* Divider */
$divider-text-color: #BEC5CC;
$divider-border-color: #E3E6E9;

$input-border-radius: 6px;
$button-border-radius: 8px;

/* Responsive */
$layout-breakpoint-s: 425px;