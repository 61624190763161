*,
*::after,
*::before {
    box-sizing: border-box;
}

html,
body,
ul,
p,
button,
h1 {
    margin: 0;
    padding: 0;
    font-family: inherit;
}

ul {
    list-style: none;
}

img {
    display: block;
}

a {
    text-decoration: none;
}

button {
    border: none;
}