@import "../../styles/variables";

.forgot-password-block {
    .form {
        margin-top: 40px;

        .button {
            &.primary {
                margin-top: 25px;
            }

            &.secondary {
                margin-top: 20px;
                padding: 0;

                a {
                    color: $social-text-color;
                    display: block;
                    padding: 12px 0;
                    width: 100%;
                }
            }
        }
    }
}