.create-password-block {

    .form {
        margin-top: 40px;

        label ~ label {
            margin-top: 25px;
        }

        .button {
            margin-top: 30px;
        }
    }
}